<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <path
            class="a"
            d="M19.5,9.751V9a2.25,2.25,0,0,0-2.25-2.25H3.751A2.25,2.25,0,0,0,1.5,9v6a2.251,2.251,0,0,0,2.25,2.25h13.5A2.25,2.25,0,0,0,19.5,15v-.75H21a1.5,1.5,0,0,0,1.5-1.5v-1.5a1.5,1.5,0,0,0-1.5-1.5Z"
        />

        <rect
            class="b"
            x="4"
            y="9.5"
            :width="indicatorWidth"
            height="5"
            rx="0.5"
            ry="0.5"
            :fill="indicatorColor"
            :stroke="indicatorColor"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

const batteryStatusColors = {
    critical: '#d32f2f',
    ok: '#41b883',
    warning: '#fdae61',
}

const batteryStatusLevels = {
    critical: 0.3,
    ok: 1,
    warning: 0.6,
}

const indicatorMaxWidth = 13

export default {
    name: 'SearchIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
        batteryLevelInPercent: {
            type: [Number, String],
            default: 10,
        },
        batteryStatus: {
            type: String,
            validator: v => Object.keys(batteryStatusLevels).includes(v),
            default: null,
        },
        warn: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        indicatorColor() {
            return this.batteryStatus
                ? batteryStatusColors[this.batteryStatus]
                : this.warn
                ? batteryStatusColors.critical
                : this.color
        },
        indicatorWidth() {
            const batteryLevel = this.batteryStatus
                ? batteryStatusLevels[this.batteryStatus]
                : this.batteryLevelInPercent / 100

            return Math.max(batteryLevel * indicatorMaxWidth, 1)
        },
    },
}
</script>

<style scoped>
.a {
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}

.b {
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
