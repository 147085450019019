<template>
    <table>
        <tr>
            <th colspan="2">{{ $t('shared.asset') }}</th>

            <th>{{ $t('measurements') }}</th>

            <th>{{ $t('time') }}</th>

            <th class="minimized"></th>
        </tr>

        <tr v-for="(item, index) in measurementsUpdatesLatest" :key="index">
            <td class="minimized">
                <img
                    class="avatar"
                    :src="item.tracker.marker"
                    :style="{ borderColor: item.tracker.color }"
                />
            </td>

            <td class="merged-left">
                {{ item.tracker.name }}
            </td>

            <td class="measurements-row">
                <div v-if="item.measurements.position">
                    <PinBoldIcon />

                    <span>{{ item.measurements.position }}</span>
                </div>

                <template v-if="item.tracker.type === 'bin'">
                    <div v-if="'distance' in item.measurements">
                        <FilledCircleIcon
                            :class="getBinColor(item.measurements.distance)"
                        />

                        <span class="sensor-data-percentage">
                            {{ item.measurements.distance }}
                        </span>
                    </div>

                    <div v-if="'battery' in item.measurements">
                        <BatteryIcon
                            :battery-level-in-percent="
                                item.measurements.battery
                            "
                        />

                        <span class="sensor-data-percentage">
                            {{ item.measurements.battery }}
                        </span>
                    </div>

                    <div v-if="'temperature' in item.measurements">
                        <ThermometerIcon width="18" height="18" />

                        <span class="sensor-data-temperature">
                            {{ item.measurements.temperature }}
                        </span>
                    </div>
                </template>

                <template v-else-if="item.tracker.type === 'machine'">
                    <div v-if="'d1' in item.measurements">
                        <FilledCircleIcon
                            :class="getMachineColor(item.measurements.d1)"
                        />

                        <span>
                            {{
                                item.measurements.d1
                                    ? $t('running')
                                    : $t('notRunning')
                            }}
                        </span>
                    </div>
                </template>

                <template v-else-if="item.tracker.type === 'parking-space'">
                    <div v-if="'distance' in item.measurements">
                        <FilledCircleIcon
                            :class="getParkingColor(item.measurements.distance)"
                        />

                        <span>
                            {{
                                item.measurements.distance
                                    ? $t('free')
                                    : $t('occupied')
                            }}
                        </span>
                    </div>

                    <div v-if="'battery' in item.measurements">
                        <BatteryIcon
                            :battery-level-in-percent="
                                item.measurements.battery
                            "
                        />

                        <span class="sensor-data-percentage">
                            {{ item.measurements.battery }}
                        </span>
                    </div>

                    <div v-if="'temperature' in item.measurements">
                        <ThermometerIcon width="18" height="18" />

                        <span class="sensor-data-temperature">
                            {{ item.measurements.temperature }}
                        </span>
                    </div>
                </template>

                <template v-else-if="item.tracker.type === 'sbb-bin'">
                    <div v-if="'distance' in item.measurements">
                        <FilledCircleIcon
                            :class="getSbbBinColor(item.measurements.distance)"
                        />

                        <span class="sensor-data-percentage">
                            {{ item.measurements.distance }}
                        </span>
                    </div>

                    <div v-if="'battery' in item.measurements">
                        <BatteryIcon
                            :battery-level-in-percent="
                                item.measurements.battery
                            "
                        />

                        <span class="sensor-data-percentage">
                            {{ item.measurements.battery }}
                        </span>
                    </div>

                    <div v-if="'temperature' in item.measurements">
                        <ThermometerIcon width="18" height="18" />

                        <span class="sensor-data-temperature">
                            {{ item.measurements.temperature }}
                        </span>
                    </div>
                </template>

                <template v-else>
                    <template v-for="measurement in commonMeasurements">
                        <div
                            v-if="measurement.key in item.measurements"
                            :key="`other-${measurement.key}`"
                        >
                            <component
                                :is="measurement.icon"
                                width="18"
                                height="18"
                                :battery-level-in-percent="
                                    measurement.key === 'battery' &&
                                        item.measurements.battery
                                "
                            />

                            <span :class="measurement.class">
                                {{ item.measurements[measurement.key] }}
                            </span>
                        </div>
                    </template>
                </template>
            </td>

            <td>
                <TimeAgo :from-datetime="item.time" />
            </td>

            <td class="minimized">
                <router-link
                    :to="{
                        name: 'detail',
                        params: { id: item.tracker.id },
                    }"
                    target="_blank"
                    :title="$t('showOnMap', [item.tracker.name])"
                >
                    <MapIcon />
                </router-link>
            </td>
        </tr>
    </table>
</template>

<script>
import { mapState } from 'vuex'

import { measurementHelper } from '@/utils'

import BatteryIcon from './icons/BatteryIcon'
import CO2Icon from './icons/CO2Icon'
import DistanceIcon from './icons/DistanceIcon'
import DropIcon from './icons/DropIcon'
import FilledCircleIcon from './icons/FilledCircleIcon'
import MapIcon from './icons/MapIcon'
import PinBoldIcon from './icons/PinBoldIcon'
import ThermometerIcon from './icons/ThermometerIcon'
import TimeAgo from './TimeAgo'
import VocIcon from './icons/VocIcon'

const valueConverters = {
    bin: measurementHelper.convertToBinLevel,
    'parking-space': measurementHelper.convertToParkingAvailability,
    'sbb-bin': measurementHelper.convertToSbbBinLevel,
}

export default {
    name: 'MeasurementsUpdatesTable',
    components: {
        BatteryIcon,
        FilledCircleIcon,
        MapIcon,
        PinBoldIcon,
        ThermometerIcon,
        TimeAgo,
    },
    data() {
        return {
            commonMeasurements: [
                {
                    key: 'battery',
                    icon: BatteryIcon,
                    class: 'sensor-data-percentage',
                },
                {
                    key: 'temperature',
                    icon: ThermometerIcon,
                    class: 'sensor-data-temperature',
                },
                {
                    key: 'distance',
                    icon: DistanceIcon,
                    class: 'sensor-data-distance',
                },
                {
                    key: 'humidity',
                    icon: DropIcon,
                    class: 'sensor-data-percentage',
                },
                {
                    key: 'co2',
                    icon: CO2Icon,
                    class: 'sensor-data-co2',
                },
                {
                    key: 'voc',
                    icon: VocIcon,
                    class: 'sensor-data-voc',
                },
            ],
        }
    },
    computed: {
        ...mapState('tracker', ['trackers']),
        measurementsUpdates() {
            return this.trackers.reduce((acc, tracker) => {
                const type = tracker.asset_details.asset_type_type
                const updates = {
                    ...tracker.asset_details.sensor_data,
                    ...(tracker.last_gps_measurement && {
                        position: {
                            value: this.$t('new'),
                            last_update: tracker.last_gps_measurement.timestamp,
                        },
                    }),
                }

                Object.entries(updates).forEach(([key, measurement]) => {
                    const bucket = `${tracker.id}-${measurement.last_update}`
                    let value = measurement.value

                    if (key === 'distance' && type in valueConverters) {
                        const converter = valueConverters[type]
                        value = converter(value)
                    }

                    if (!acc[bucket]) {
                        acc[bucket] = {
                            time: measurement.last_update,
                            measurements: {},
                            tracker: {
                                id: tracker.id,
                                type,
                                name: tracker.asset_details.name,
                                marker: tracker.asset_details.asset_type_marker,
                                color: tracker.asset_details.color,
                            },
                        }
                    }

                    acc[bucket].measurements[key] = value
                })

                return acc
            }, {})
        },
        measurementsUpdatesLatest() {
            return Object.values(this.measurementsUpdates)
                .sort((a, b) => new Date(b.time) - new Date(a.time))
                .slice(0, 5)
        },
    },
    methods: {
        getBinColor(value) {
            return value < 40 ? 'green' : value > 80 ? 'red' : 'orange'
        },
        getMachineColor(value) {
            return value < 40 ? 'green' : 'red'
        },
        getParkingColor(value) {
            return value ? 'green' : 'red'
        },
        getSbbBinColor(value) {
            return value < 40 ? 'green' : value > 80 ? 'red' : 'orange'
        },
    },
}
</script>

<i18n>
{
    "en": {
        "battery": "Battery",
        "co2": "Carbon dioxide",
        "distance": "Distance",
        "free": "Free",
        "humidity": "Humidity",
        "measurements": "Measurements",
        "new": "New",
        "notRunning": "Not running",
        "occupied": "Occupied",
        "running": "Running",
        "showOnMap": "Show {0} on map",
        "temperature": "Temperature",
        "time": "Time",
        "voc": "VOC"
    },
    "de": {
        "battery": "Batterie",
        "co2": "Kohlenstoffdioxid",
        "distance": "Distanz",
        "free": "Frei",
        "humidity": "Luftfeuchtigkeit",
        "measurements": "Messungen",
        "new": "New",
        "notRunning": "Ausser Betrieb",
        "occupied": "Besetzt",
        "running": "In Betrieb",
        "showOnMap": "{0} auf Karte anzeigen",
        "temperature": "Temperatur",
        "time": "Zeit",
        "voc": "VOC"
    },
    "it": {
        "battery": "Batteria",
        "co2": "Anidride cabonica",
        "distance": "Distanza",
        "free": "Libero",
        "humidity": "Umidità",
        "measurements": "Measurements",
        "new": "New",
        "notRunning": "Fuori uso",
        "occupied": "Occupato",
        "running": "In uso",
        "showOnMap": "Mostra {0} sulla mappa",
        "temperature": "Temperatura",
        "time": "Tempo",
        "voc": "VOC"
    }
}
</i18n>

<style lang="scss" scoped>
table {
    padding-top: 1rem;
    width: 100%;
    border-spacing: 0;

    tr {
        th,
        td {
            &:not(:first-child) {
                border-left: $style-border;
            }
        }

        th {
            padding: 1rem;
            text-align: left;
        }

        td {
            padding: 0.5rem 1rem;
            border-top: $style-border;

            &.minimized {
                width: 1%;
                white-space: nowrap;
            }

            &.merged-left {
                padding-left: 0;
                border-left: none;
            }
        }
    }
}

.measurements-row {
    & > div {
        display: inline-flex;
        align-items: flex-end;
        margin-right: 4px;
        margin-right: 20px;

        span {
            margin-left: 4px;
        }
    }
}

.avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 3px solid;
    padding: 6px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
}

.green {
    color: $color-green;
}

.orange {
    color: $color-orange;
}

.red {
    color: $color-red;
}
</style>
